export default defineNuxtRouteMiddleware((to, from) => {
    const device = useDevice();

    if (device.isIos) {
        return navigateTo('https://apps.apple.com/us/app/domino/id6461727750', { external: true });
    }

    if (device.isMobileOrTablet) {
        return navigateTo('https://play.google.com/store/apps/details?id=ru.dpizza.app', { external: true });
    }

    return navigateTo('/');
});